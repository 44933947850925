/* Components Molecule */

.molecule-wrapper {
  background-color: #1d1c1c;
  border: 2px solid #302f2e;
  border-radius: 10px;
  text-align: right;
  padding: 0 24px;
  height: 66px;

  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  @include respond2(633px) {
    padding: 0 16px;
  }
}

.molecute-point {
  background-color: #b9b9b9;
  height: 8px;
  width: 8px;
  border-radius: 10px;
}

.molecule-p {
  font-size: 24px;
  line-height: 36px;
  color: #b9b9b9;
  font-weight: 420;
  @include respond2(513px) {
    font-size: 20px;
  }
}

/* Components Primary Button */
.primary-btn-fw {
  color: #141414;
  background-color: #ffe8a3;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 460;
  height: 66px;
  width: 100%;
  white-space: nowrap;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.primary-btn {
  flex-shrink: 0;
  // width: auto;
  padding: 0 48px;
}

/* Link Component  */
.link-btn-wrapper {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 8px;
}

.link-btn-text {
  font-size: 26px;
  font-weight: 420;
  color: #fff;
}

// left toplink {

.section-link {
  position: absolute;
  left: 0px;
  top: 0;
  display: table;
  @include respond2(1300px) {
    left: 32px;
    top: 0;
  }
}
.section-link-icon {
  margin-right: 8px;
}
.section-link-atag {
  color: #b9b9b9;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  text-decoration-line: underline;
  transition: all 0.3s ease;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: $primary-color;
  }
  svg path {
    transition: all 0.3s ease;
  }
  &:hover svg {
    path {
      stroke: $primary-color;
    }
  }
}

/* Button FW */
// .primary-btn-fw {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 66px;
//   width: 100%;
//   background-color: #ffe8a3;
//   border-radius: 10px;
//   font-size: 26px;
//   font-weight: 460;
//   color: #141414;
// }

.test {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 66px;
  background-color: #ffe8a3;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 460;
  color: #141414;
  padding: 0 48px;
}

.test-wf {
  width: 100%;
}

//popup
.popup {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(yellow, 0.8);
  z-index: 2021;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &__content {
    width: 50vw;
    height: 50rem;
    background-color: gray;
    box-shadow: 0 2rem 4rem rgba(blue, 0.2);
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
    transition: all 0.4s;

    &.flex {
      flex-direction: column;
    }

    img {
      display: block;
      height: 25rem;
      width: 25rem;
    }
  }

  // open states
  &:target {
    opacity: 1;
    visibility: visible;
  }

  &:target &__content {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  &__close {
    color: green;
    background-color: transparent;
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    font-size: 3rem;
    text-decoration: none;
    display: inline-block;
    transition: all 0.2s;
    line-height: 1;

    &:hover {
      color: white;
    }
  }
}

.primary-hover-styles {
  background: rgba(176, 163, 255, 0.04);
  border: 2px solid rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(80px);
  border-radius: 10px;
  padding: 16px;
  transition: all 0.3s ease;

  &-hover {
    background: rgba(255, 232, 163, 0.16);
    border: 2px solid rgba(255, 232, 163, 0.26);
    p,
    &.molecule-p {
      color: $white;
    }
    path {
      fill: $primary-color;
    }
  }

  &-clicked {
    background: #ffe8a3;
    border: 2px solid rgba(255, 255, 255, 0.06);
    p,
    &.molecule-p {
      color: $body-background;
    }
    path {
      fill: $body-background;
    }
  }
}

// map address
.map-address {
  p {
    line-height: 125%;
  }
}
