.ml-initial {margin-left: initial !important;}
.mr-initial {margin-right: initial !important;}
.ml-auto {margin-left: auto !important;}
.mr-auto {margin-right: auto !important;}

.b-2 {
    position: relative;
    bottom: -2;
}
.m0 {margin: 0;}
.m1 {margin: 1px;}
.m2 {margin: 2px;}
.m3 {margin: 3px;}
.m4 {margin: 4px;}
.m5 {margin: 5px;}
.m6 {margin: 6px;}
.m7 {margin: 7px;}
.m8 {margin: 8px;}
.m9 {margin: 9px;}
.m10 {margin: 10px;}
.m11 {margin: 11px;}
.m12 {margin: 12px;}
.m13 {margin: 13px;}
.m14 {margin: 14px;}
.m15 {margin: 15px;}
.m16 {margin: 16px;}
.m17 {margin: 17px;}
.m18 {margin: 18px;}
.m19 {margin: 19px;}
.m20 {margin: 20px;}
.m21 {margin: 21px;}
.m22 {margin: 22px;}
.m23 {margin: 23px;}
.m24 {margin: 24px;}
.m25 {margin: 25px;}
.m26 {margin: 26px;}
.m27 {margin: 27px;}
.m28 {margin: 28px;}
.m29 {margin: 29px;}
.m30 {margin: 30px;}
.m31 {margin: 31px;}
.m32 {margin: 32px;}
.m33 {margin: 33px;}
.m34 {margin: 34px;}
.m35 {margin: 35px;}
.m36 {margin: 36px;}
.m37 {margin: 37px;}
.m38 {margin: 38px;}
.m39 {margin: 39px;}
.m40 {margin: 40px;}
.m41 {margin: 41px;}
.m42 {margin: 42px;}
.m43 {margin: 43px;}
.m44 {margin: 44px;}
.m45 {margin: 45px;}
.m46 {margin: 46px;}
.m47 {margin: 47px;}
.m48 {margin: 48px;}
.m49 {margin: 49px;}
.m50 {margin: 50px;}

// margin-top
.mt-0 {margin-top: 0;}
.mt-1 {margin-top: 1px;}
.mt-2 {margin-top: 2px;}
.mt-3 {margin-top: 3px;}
.mt-4 {margin-top: 4px;}
.mt-5 {margin-top: 5px;}
.mt-6 {margin-top: 6px;}
.mt-7 {margin-top: 7px;}
.mt-8 {margin-top: 8px;}
.mt-9 {margin-top: 9px;}
.mt-10 {margin-top: 10px;}
.mt-11 {margin-top: 11px;}
.mt-12 {margin-top: 12px;}
.mt-13 {margin-top: 13px;}
.mt-14 {margin-top: 14px;}
.mt-15 {margin-top: 15px;}
.mt-16 {margin-top: 16px;}
.mt-17 {margin-top: 17px;}
.mt-18 {margin-top: 18px;}
.mt-19 {margin-top: 19px;}
.mt-20 {margin-top: 20px;}
.mt-21 {margin-top: 21px;}
.mt-22 {margin-top: 22px;}
.mt-23 {margin-top: 23px;}
.mt-24 {margin-top: 24px;}
.mt-25 {margin-top: 25px;}
.mt-26 {margin-top: 26px;}
.mt-27 {margin-top: 27px;}
.mt-28 {margin-top: 28px;}
.mt-29 {margin-top: 29px;}
.mt-30 {margin-top: 30px;}
.mt-31 {margin-top: 31px;}
.mt-32 {margin-top: 32px;}
.mt-33 {margin-top: 33px;}
.mt-34 {margin-top: 34px;}
.mt-35 {margin-top: 35px;}
.mt-36 {margin-top: 36px;}
.mt-37 {margin-top: 37px;}
.mt-38 {margin-top: 38px;}
.mt-39 {margin-top: 39px;}
.mt-40 {margin-top: 40px;}
.mt-41 {margin-top: 41px;}
.mt-42 {margin-top: 42px;}
.mt-43 {margin-top: 43px;}
.mt-44 {margin-top: 44px;}
.mt-45 {margin-top: 45px;}
.mt-46 {margin-top: 46px;}
.mt-47 {margin-top: 47px;}
.mt-48 {margin-top: 48px;}
.mt-49 {margin-top: 49px;}
.mt-50 {margin-top: 50px;}
// for 1 use
.mt-60 {margin-top: 60px;}
// margin-right
.mr-0 {margin-right: 0;}
.mr-1 {margin-right: 1px;}
.mr-2 {margin-right: 2px;}
.mr-3 {margin-right: 3px;}
.mr-4 {margin-right: 4px;}
.mr-5 {margin-right: 5px;}
.mr-6 {margin-right: 6px;}
.mr-7 {margin-right: 7px;}
.mr-8 {margin-right: 8px;}
.mr-9 {margin-right: 9px;}
.mr-10 {margin-right: 10px;}
.mr-11 {margin-right: 11px;}
.mr-12 {margin-right: 12px;}
.mr-13 {margin-right: 13px;}
.mr-14 {margin-right: 14px;}
.mr-15 {margin-right: 15px;}
.mr-16 {margin-right: 16px;}
.mr-17 {margin-right: 17px;}
.mr-18 {margin-right: 18px;}
.mr-19 {margin-right: 19px;}
.mr-20 {margin-right: 20px;}
.mr-21 {margin-right: 21px;}
.mr-22 {margin-right: 22px;}
.mr-23 {margin-right: 23px;}
.mr-24 {margin-right: 24px;}
.mr-25 {margin-right: 25px;}
.mr-26 {margin-right: 26px;}
.mr-27 {margin-right: 27px;}
.mr-28 {margin-right: 28px;}
.mr-29 {margin-right: 29px;}
.mr-30 {margin-right: 30px;}
.mr-31 {margin-right: 31px;}
.mr-32 {margin-right: 32px;}
.mr-33 {margin-right: 33px;}
.mr-34 {margin-right: 34px;}
.mr-35 {margin-right: 35px;}
.mr-36 {margin-right: 36px;}
.mr-37 {margin-right: 37px;}
.mr-38 {margin-right: 38px;}
.mr-39 {margin-right: 39px;}
.mr-40 {margin-right: 40px;}
.mr-41 {margin-right: 41px;}
.mr-42 {margin-right: 42px;}
.mr-43 {margin-right: 43px;}
.mr-44 {margin-right: 44px;}
.mr-45 {margin-right: 45px;}
.mr-46 {margin-right: 46px;}
.mr-47 {margin-right: 47px;}
.mr-48 {margin-right: 48px;}
.mr-49 {margin-right: 49px;}
.mr-50 {margin-right: 50px;}

// margin-bottom
.mb-0 {margin-bottom: 0;}
.mb-1 {margin-bottom: 1px;}
.mb-2 {margin-bottom: 2px;}
.mb-3 {margin-bottom: 3px;}
.mb-4 {margin-bottom: 4px;}
.mb-5 {margin-bottom: 5px;}
.mb-6 {margin-bottom: 6px;}
.mb-7 {margin-bottom: 7px;}
.mb-8 {margin-bottom: 8px;}
.mb-9 {margin-bottom: 9px;}
.mb-10 {margin-bottom: 10px;}
.mb-11 {margin-bottom: 11px;}
.mb-12 {margin-bottom: 12px;}
.mb-13 {margin-bottom: 13px;}
.mb-14 {margin-bottom: 14px;}
.mb-15 {margin-bottom: 15px;}
.mb-16 {margin-bottom: 16px;}
.mb-17 {margin-bottom: 17px;}
.mb-18 {margin-bottom: 18px;}
.mb-19 {margin-bottom: 19px;}
.mb-20 {margin-bottom: 20px;}
.mb-21 {margin-bottom: 21px;}
.mb-22 {margin-bottom: 22px;}
.mb-23 {margin-bottom: 23px;}
.mb-24 {margin-bottom: 24px;}
.mb-25 {margin-bottom: 25px;}
.mb-26 {margin-bottom: 26px;}
.mb-27 {margin-bottom: 27px;}
.mb-28 {margin-bottom: 28px;}
.mb-29 {margin-bottom: 29px;}
.mb-30 {margin-bottom: 30px;}
.mb-31 {margin-bottom: 31px;}
.mb-32 {margin-bottom: 32px;}
.mb-33 {margin-bottom: 33px;}
.mb-34 {margin-bottom: 34px;}
.mb-35 {margin-bottom: 35px;}
.mb-36 {margin-bottom: 36px;}
.mb-37 {margin-bottom: 37px;}
.mb-38 {margin-bottom: 38px;}
.mb-39 {margin-bottom: 39px;}
.mb-40 {margin-bottom: 40px;}
.mb-41 {margin-bottom: 41px;}
.mb-42 {margin-bottom: 42px;}
.mb-43 {margin-bottom: 43px;}
.mb-44 {margin-bottom: 44px;}
.mb-45 {margin-bottom: 45px;}
.mb-46 {margin-bottom: 46px;}
.mb-47 {margin-bottom: 47px;}
.mb-48 {margin-bottom: 48px;}
.mb-49 {margin-bottom: 49px;}
.mb-50 {margin-bottom: 50px;}

// margin-left
.ml-0 {margin-left: 0;}
.ml-1 {margin-left: 1px;}
.ml-2 {margin-left: 2px;}
.ml-3 {margin-left: 3px;}
.ml-4 {margin-left: 4px;}
.ml-5 {margin-left: 5px;}
.ml-6 {margin-left: 6px;}
.ml-7 {margin-left: 7px;}
.ml-8 {margin-left: 8px;}
.ml-9 {margin-left: 9px;}
.ml-10 {margin-left: 10px;}
.ml-11 {margin-left: 11px;}
.ml-12 {margin-left: 12px;}
.ml-13 {margin-left: 13px;}
.ml-14 {margin-left: 14px;}
.ml-15 {margin-left: 15px;}
.ml-16 {margin-left: 16px;}
.ml-17 {margin-left: 17px;}
.ml-18 {margin-left: 18px;}
.ml-19 {margin-left: 19px;}
.ml-20 {margin-left: 20px;}
.ml-21 {margin-left: 21px;}
.ml-22 {margin-left: 22px;}
.ml-23 {margin-left: 23px;}
.ml-24 {margin-left: 24px;}
.ml-25 {margin-left: 25px;}
.ml-26 {margin-left: 26px;}
.ml-27 {margin-left: 27px;}
.ml-28 {margin-left: 28px;}
.ml-29 {margin-left: 29px;}
.ml-30 {margin-left: 30px;}
.ml-31 {margin-left: 31px;}
.ml-32 {margin-left: 32px;}
.ml-33 {margin-left: 33px;}
.ml-34 {margin-left: 34px;}
.ml-35 {margin-left: 35px;}
.ml-36 {margin-left: 36px;}
.ml-37 {margin-left: 37px;}
.ml-38 {margin-left: 38px;}
.ml-39 {margin-left: 39px;}
.ml-40 {margin-left: 40px;}
.ml-41 {margin-left: 41px;}
.ml-42 {margin-left: 42px;}
.ml-43 {margin-left: 43px;}
.ml-44 {margin-left: 44px;}
.ml-45 {margin-left: 45px;}
.ml-46 {margin-left: 46px;}
.ml-47 {margin-left: 47px;}
.ml-48 {margin-left: 48px;}
.ml-49 {margin-left: 49px;}
.ml-50 {margin-left: 50px;}

// padding
.p0 {padding: 0;}
.p1 {padding: 1px;}
.p2 {padding: 2px;}
.p3 {padding: 3px;}
.p4 {padding: 4px;}
.p5 {padding: 5px;}
.p6 {padding: 6px;}
.p7 {padding: 7px;}
.p8 {padding: 8px;}
.p9 {padding: 9px;}
.p10 {padding: 10px;}
.p11 {padding: 11px;}
.p12 {padding: 12px;}
.p13 {padding: 13px;}
.p14 {padding: 14px;}
.p15 {padding: 15px;}
.p16 {padding: 16px;}
.p17 {padding: 17px;}
.p18 {padding: 18px;}
.p19 {padding: 19px;}
.p20 {padding: 20px;}
.p21 {padding: 21px;}
.p22 {padding: 22px;}
.p23 {padding: 23px;}
.p24 {padding: 24px;}
.p25 {padding: 25px;}
.p26 {padding: 26px;}
.p27 {padding: 27px;}
.p28 {padding: 28px;}
.p29 {padding: 29px;}
.p30 {padding: 30px;}
.p31 {padding: 31px;}
.p32 {padding: 32px;}
.p33 {padding: 33px;}
.p34 {padding: 34px;}
.p35 {padding: 35px;}
.p36 {padding: 36px;}
.p37 {padding: 37px;}
.p38 {padding: 38px;}
.p39 {padding: 39px;}
.p40 {padding: 40px;}
.p41 {padding: 41px;}
.p42 {padding: 42px;}
.p43 {padding: 43px;}
.p44 {padding: 44px;}
.p45 {padding: 45px;}
.p46 {padding: 46px;}
.p47 {padding: 47px;}
.p48 {padding: 48px;}
.p49 {padding: 49px;}
.p50 {padding: 50px;}

// padding-top
.pt-0 {padding-top: 0;}
.pt-1 {padding-top: 1px;}
.pt-2 {padding-top: 2px;}
.pt-3 {padding-top: 3px;}
.pt-4 {padding-top: 4px;}
.pt-5 {padding-top: 5px;}
.pt-6 {padding-top: 6px;}
.pt-7 {padding-top: 7px;}
.pt-8 {padding-top: 8px;}
.pt-9 {padding-top: 9px;}
.pt-10 {padding-top: 10px;}
.pt-11 {padding-top: 11px;}
.pt-12 {padding-top: 12px;}
.pt-13 {padding-top: 13px;}
.pt-14 {padding-top: 14px;}
.pt-15 {padding-top: 15px;}
.pt-16 {padding-top: 16px;}
.pt-17 {padding-top: 17px;}
.pt-18 {padding-top: 18px;}
.pt-19 {padding-top: 19px;}
.pt-20 {padding-top: 20px;}
.pt-21 {padding-top: 21px;}
.pt-22 {padding-top: 22px;}
.pt-23 {padding-top: 23px;}
.pt-24 {padding-top: 24px;}
.pt-25 {padding-top: 25px;}
.pt-26 {padding-top: 26px;}
.pt-27 {padding-top: 27px;}
.pt-28 {padding-top: 28px;}
.pt-29 {padding-top: 29px;}
.pt-30 {padding-top: 30px;}
.pt-31 {padding-top: 31px;}
.pt-32 {padding-top: 32px;}
.pt-33 {padding-top: 33px;}
.pt-34 {padding-top: 34px;}
.pt-35 {padding-top: 35px;}
.pt-36 {padding-top: 36px;}
.pt-37 {padding-top: 37px;}
.pt-38 {padding-top: 38px;}
.pt-39 {padding-top: 39px;}
.pt-40 {padding-top: 40px;}
.pt-41 {padding-top: 41px;}
.pt-42 {padding-top: 42px;}
.pt-43 {padding-top: 43px;}
.pt-44 {padding-top: 44px;}
.pt-45 {padding-top: 45px;}
.pt-46 {padding-top: 46px;}
.pt-47 {padding-top: 47px;}
.pt-48 {padding-top: 48px;}
.pt-49 {padding-top: 49px;}
.pt-50 {padding-top: 50px;}
// padding-right
.pr-0 {padding-right: 0;}
.pr-1 {padding-right: 1px;}
.pr-2 {padding-right: 2px;}
.pr-3 {padding-right: 3px;}
.pr-4 {padding-right: 4px;}
.pr-5 {padding-right: 5px;}
.pr-6 {padding-right: 6px;}
.pr-7 {padding-right: 7px;}
.pr-8 {padding-right: 8px;}
.pr-9 {padding-right: 9px;}
.pr-10 {padding-right: 10px;}
.pr-11 {padding-right: 11px;}
.pr-12 {padding-right: 12px;}
.pr-13 {padding-right: 13px;}
.pr-14 {padding-right: 14px;}
.pr-15 {padding-right: 15px;}
.pr-16 {padding-right: 16px;}
.pr-17 {padding-right: 17px;}
.pr-18 {padding-right: 18px;}
.pr-19 {padding-right: 19px;}
.pr-20 {padding-right: 20px;}
.pr-21 {padding-right: 21px;}
.pr-22 {padding-right: 22px;}
.pr-23 {padding-right: 23px;}
.pr-24 {padding-right: 24px;}
.pr-25 {padding-right: 25px;}
.pr-26 {padding-right: 26px;}
.pr-27 {padding-right: 27px;}
.pr-28 {padding-right: 28px;}
.pr-29 {padding-right: 29px;}
.pr-30 {padding-right: 30px;}
.pr-31 {padding-right: 31px;}
.pr-32 {padding-right: 32px;}
.pr-33 {padding-right: 33px;}
.pr-34 {padding-right: 34px;}
.pr-35 {padding-right: 35px;}
.pr-36 {padding-right: 36px;}
.pr-37 {padding-right: 37px;}
.pr-38 {padding-right: 38px;}
.pr-39 {padding-right: 39px;}
.pr-40 {padding-right: 40px;}
.pr-41 {padding-right: 41px;}
.pr-42 {padding-right: 42px;}
.pr-43 {padding-right: 43px;}
.pr-44 {padding-right: 44px;}
.pr-45 {padding-right: 45px;}
.pr-46 {padding-right: 46px;}
.pr-47 {padding-right: 47px;}
.pr-48 {padding-right: 48px;}
.pr-49 {padding-right: 49px;}
.pr-50 {padding-right: 50px;}

// padding-bottom
.pb-0 {padding-bottom: 0;}
.pb-1 {padding-bottom: 1px;}
.pb-2 {padding-bottom: 2px;}
.pb-3 {padding-bottom: 3px;}
.pb-4 {padding-bottom: 4px;}
.pb-5 {padding-bottom: 5px;}
.pb-6 {padding-bottom: 6px;}
.pb-7 {padding-bottom: 7px;}
.pb-8 {padding-bottom: 8px;}
.pb-9 {padding-bottom: 9px;}
.pb-10 {padding-bottom: 10px;}
.pb-11 {padding-bottom: 11px;}
.pb-12 {padding-bottom: 12px;}
.pb-13 {padding-bottom: 13px;}
.pb-14 {padding-bottom: 14px;}
.pb-15 {padding-bottom: 15px;}
.pb-16 {padding-bottom: 16px;}
.pb-17 {padding-bottom: 17px;}
.pb-18 {padding-bottom: 18px;}
.pb-19 {padding-bottom: 19px;}
.pb-20 {padding-bottom: 20px;}
.pb-21 {padding-bottom: 21px;}
.pb-22 {padding-bottom: 22px;}
.pb-23 {padding-bottom: 23px;}
.pb-24 {padding-bottom: 24px;}
.pb-25 {padding-bottom: 25px;}
.pb-26 {padding-bottom: 26px;}
.pb-27 {padding-bottom: 27px;}
.pb-28 {padding-bottom: 28px;}
.pb-29 {padding-bottom: 29px;}
.pb-30 {padding-bottom: 30px;}
.pb-31 {padding-bottom: 31px;}
.pb-32 {padding-bottom: 32px;}
.pb-33 {padding-bottom: 33px;}
.pb-34 {padding-bottom: 34px;}
.pb-35 {padding-bottom: 35px;}
.pb-36 {padding-bottom: 36px;}
.pb-37 {padding-bottom: 37px;}
.pb-38 {padding-bottom: 38px;}
.pb-39 {padding-bottom: 39px;}
.pb-40 {padding-bottom: 40px;}
.pb-41 {padding-bottom: 41px;}
.pb-42 {padding-bottom: 42px;}
.pb-43 {padding-bottom: 43px;}
.pb-44 {padding-bottom: 44px;}
.pb-45 {padding-bottom: 45px;}
.pb-46 {padding-bottom: 46px;}
.pb-47 {padding-bottom: 47px;}
.pb-48 {padding-bottom: 48px;}
.pb-49 {padding-bottom: 49px;}
.pb-50 {padding-bottom: 50px;}

// padding-left
.pl-0 {padding-left: 0;}
.pl-1 {padding-left: 1px;}
.pl-2 {padding-left: 2px;}
.pl-3 {padding-left: 3px;}
.pl-4 {padding-left: 4px;}
.pl-5 {padding-left: 5px;}
.pl-6 {padding-left: 6px;}
.pl-7 {padding-left: 7px;}
.pl-8 {padding-left: 8px;}
.pl-9 {padding-left: 9px;}
.pl-10 {padding-left: 10px;}
.pl-11 {padding-left: 11px;}
.pl-12 {padding-left: 12px;}
.pl-13 {padding-left: 13px;}
.pl-14 {padding-left: 14px;}
.pl-15 {padding-left: 15px;}
.pl-16 {padding-left: 16px;}
.pl-17 {padding-left: 17px;}
.pl-18 {padding-left: 18px;}
.pl-19 {padding-left: 19px;}
.pl-20 {padding-left: 20px;}
.pl-21 {padding-left: 21px;}
.pl-22 {padding-left: 22px;}
.pl-23 {padding-left: 23px;}
.pl-24 {padding-left: 24px;}
.pl-25 {padding-left: 25px;}
.pl-26 {padding-left: 26px;}
.pl-27 {padding-left: 27px;}
.pl-28 {padding-left: 28px;}
.pl-29 {padding-left: 29px;}
.pl-30 {padding-left: 30px;}
.pl-31 {padding-left: 31px;}
.pl-32 {padding-left: 32px;}
.pl-33 {padding-left: 33px;}
.pl-34 {padding-left: 34px;}
.pl-35 {padding-left: 35px;}
.pl-36 {padding-left: 36px;}
.pl-37 {padding-left: 37px;}
.pl-38 {padding-left: 38px;}
.pl-39 {padding-left: 39px;}
.pl-40 {padding-left: 40px;}
.pl-41 {padding-left: 41px;}
.pl-42 {padding-left: 42px;}
.pl-43 {padding-left: 43px;}
.pl-44 {padding-left: 44px;}
.pl-45 {padding-left: 45px;}
.pl-46 {padding-left: 46px;}
.pl-47 {padding-left: 47px;}
.pl-48 {padding-left: 48px;}
.pl-49 {padding-left: 49px;}
.pl-50 {padding-left: 50px;}
