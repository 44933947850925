@font-face {
  font-family: "29LTAzer";
  src: url(../../public/fonts/29LTAzer-Light.woff);
  font-weight: 380;
  font-style: normal;
}

@font-face {
  font-family: "29LTAzer";
  src: url(../../public/fonts/29LTAzer-Regular.woff);
  font-weight: 420;
  font-style: normal;
}

@font-face {
  font-family: "29LTAzer";
  src: url(../../public/fonts/29LTAzer-Medium.woff);
  font-weight: 460;
  font-style: normal;
}

@font-face {
  font-family: "29LTAzer";
  src: url(../../public/fonts/29LTAzer-Bold.woff);
  font-weight: 500;
  font-style: normal;
}

$body-background: #141414;
$body-background-light: #f7f7f7;
$body-color: #b9b9b9;
$body-color-light: #717171;
$primary-color: #ffe8a3;
$primary-color-light: #f1c338;
$white: #ffffff;
$section-background-color: #191919;
$navbar-item-color: #949493;
$fontArabic: "29LTAzer";

@mixin respond2($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}
@mixin imageBg {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(80px);
  border-radius: 16px;
}

@mixin inputAndTextareaTextType {
  font-family: $fontArabic;
  font-weight: regular;
  font-size: 2rem;
  line-height: 4.2rem;
}

@mixin labelTextType {
  font-family: $fontArabic;
  font-weight: regular;
  font-size: 20px;
  line-height: 42px;

  color: $body-color;
}

@mixin fontOn600Px {
  font-size: 16px;
  font-weight: 420;
  line-height: 28px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  background-color: $body-background;
  color: $body-color;
  font-family: $fontArabic;
  font-style: normal;
  max-width: 1920px;
  margin: 0 auto;
  overflow-x: hidden;
}
a {
  font-family: $fontArabic;
  font-style: normal;
  text-decoration: none;
  &:hover {
    color: $body-background;
  }
  color: $body-background;
}

button {
  border: none;
  cursor: pointer;
  font-family: $fontArabic;
}
img {
  display: block;
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fontArabic;
  color: $white;
}

label {
  font-family: $fontArabic;
}

p {
  font-family: $fontArabic;
  font-style: normal;
  font-weight: 420;
  font-size: 20px;
  line-height: 180%;
  /* identical to box height, or 36px */
  color: $body-color;
  @include respond2(600px) {
    @include fontOn600Px;
  }
  margin-bottom: 0;
}

dl,
ol,
ul {
  margin-bottom: 0;
}

ol,
ul {
  padding-left: 0;
}

svg {
  display: block;
}

label {
  @include labelTextType;
  margin-bottom: 0.8rem;
  display: block;
}

input,
select,
textarea {
  color: #fff;
}

.form-row input[type="text"],
textarea {
  color: #fff;
}

input {
  outline: none;
  margin: 0;
  color: #fff;

  &::-webkit-input-placeholder {
    font-family: $fontArabic;
    font-weight: regular;
    font-size: 20px;
    color: #595959;
  }
  &:-moz-placeholder {
    font-family: $fontArabic;
    font-weight: regular;
    font-size: 20px;
    color: #595959;
  }
  &::-moz-placeholder {
    font-family: $fontArabic;
    font-weight: regular;
    font-size: 20px;
    color: #595959;
  }
  &:-ms-input-placeholder {
    font-family: $fontArabic;
    font-weight: regular;
    font-size: 20px;
    color: #595959;
  }

  &:focus {
    border-color: transparent !important;

    &::-webkit-input-placeholder {
      color: transparent;
    }
    &::-webkit-input-placeholder {
      color: transparent;
    }
    &:-moz-placeholder {
      color: transparent;
    }
    &::-moz-placeholder {
      color: transparent;
    }
    &:-ms-input-placeholder {
      color: transparent;
    }
  }
}

textarea {
  border: none;
  outline: none;
  margin: 0;
  display: block;
  resize: none;

  &::-webkit-input-placeholder {
    color: #595959;
    font-family: $fontArabic;
  }
  &:-moz-placeholder {
    color: #595959;
    font-family: $fontArabic;
  }
  &::-moz-placeholder {
    color: #595959;
    font-family: $fontArabic;
  }
  &:-ms-input-placeholder {
    color: #595959;
    font-family: $fontArabic;
  }

  &:focus {
    border-color: transparent !important;

    &::-webkit-input-placeholder {
      color: transparent;
    }
    &:-moz-placeholder {
      color: transparent;
    }
    &::-moz-placeholder {
      color: transparent;
    }
    &:-ms-input-placeholder {
      color: transparent;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

input,
textarea {
  box-sizing: border-box;
  background: rgba(26, 26, 26, 0.72);
  backdrop-filter: blur(80px);
  border: none;
  border-radius: 8px;
  padding: 0.9rem 2.4rem;

  width: 100%;

  @include inputAndTextareaTextType;
  color: #fff;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

ul {
  list-style-type: none;
}

.container-1440 {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 100px;
  @include respond2(1300px) {
    padding: 0 72px;
  }
  @include respond2(1000px) {
    padding: 0 50px;
  }
  @include respond2(692px) {
    padding: 0 16px;
  }
}

.section-padding {
  padding: 60px 106px;
  @include respond2(1300px) {
    padding: 48px 32px;
  }
  @include respond2(513px) {
    padding: 32px 16px;
  }
}

.section-background {
  background: $section-background-color;
  backdrop-filter: blur(80px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 16px;
}

.section-heading {
  font-family: $fontArabic;
  font-style: normal;
  font-weight: 460;
  font-size: 48px;
  line-height: 48px;
  /* identical to box height */
}
.section-heading-h2 {
  font-family: $fontArabic;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 26px;
  /* identical to box height */
}

.page-divider {
  margin-top: 100px;
}

.text-right {
  text-align: right;
}

.big-heading {
  font-family: $fontArabic;
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 70px;
  /* or 109% */
  @include respond2(500px) {
    font-size: 48px;
  }
  @include respond2(529px) {
    line-height: 1;
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
}

.hover-primary {
  color: #949493;
  transition: all 0.3s ease;
  display: inline-block;
  position: relative;

  &:hover {
    color: $primary-color !important;
  }

  &::after {
    content: "";
    position: absolute;
    right: 50%;
    bottom: -8px;
    transform: translateX(50%);
    width: 0;
    height: 4px;
    transform-origin: bottom center;
    transition: all 0.3s ease;
  }

  &:hover::after {
    background-color: $primary-color;
    width: 25px;
    opacity: 1;
  }
}

.nav-active {
  color: $primary-color !important;
  transition: all 0.3s ease;
  display: inline-block;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    right: 50%;
    bottom: -8px;
    transform: translateX(50%);
    background-color: $primary-color;
    width: 25px;
    opacity: 1;
    height: 4px;
    transform-origin: bottom center;
    transition: all 0.3s ease;
  }
  &:hover {
    color: #ffe8a3;
  }
}

.page-starting-padding {
  padding-top: 150px;
}

//
.bg-to-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(../../public/images/FooterShade.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 450px;
  z-index: -1001;

  &::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1000;
    background: -ms-linear-gradient(
      0deg,
      rgba(20, 20, 20, 0.5) 0%,
      rgba(20, 20, 20, 0.8) 0%,
      rgba(20, 20, 20, 1) 100%
    );
    background: -o-linear-gradient(
      0deg,
      rgba(20, 20, 20, 0.5) 0%,
      rgba(20, 20, 20, 0.8) 0%,
      rgba(20, 20, 20, 1) 100%
    );
    background: -moz-linear-gradient(
      0deg,
      rgba(20, 20, 20, 0.5) 0%,
      rgba(20, 20, 20, 0.8) 0%,
      rgba(20, 20, 20, 1) 100%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(20, 20, 20, 0.5) 0%,
      rgba(20, 20, 20, 0.8) 0%,
      rgba(20, 20, 20, 1) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(20, 20, 20, 0.5) 0%,
      rgba(20, 20, 20, 0.8) 0%,
      rgba(20, 20, 20, 1) 100%
    );
  }
}

.bg-to-top {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(../../public/images/HeroShade.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 850px;
  z-index: -999;

  &::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -998;
    background: -ms-linear-gradient(
      180deg,
      rgba(20, 20, 20, 0.5) 0%,
      rgba(20, 20, 20, 0.8) 0%,
      rgba(20, 20, 20, 1) 100%
    );
    background: -o-linear-gradient(
      180deg,
      rgba(20, 20, 20, 0.5) 0%,
      rgba(20, 20, 20, 0.8) 0%,
      rgba(20, 20, 20, 1) 100%
    );
    background: -moz-linear-gradient(
      180deg,
      rgba(20, 20, 20, 0.5) 0%,
      rgba(20, 20, 20, 0.8) 0%,
      rgba(20, 20, 20, 1) 100%
    );
    background: -webkit-linear-gradient(
      180deg,
      rgba(20, 20, 20, 0.5) 0%,
      rgba(20, 20, 20, 0.8) 0%,
      rgba(20, 20, 20, 1) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(20, 20, 20, 0.5) 0%,
      rgba(20, 20, 20, 0.8) 0%,
      rgba(20, 20, 20, 1) 100%
    );
  }
}

.scale-image-on-hover {
  overflow: hidden !important;
  div {
    transition: all 0.5s ease;
    cursor: pointer;
    &:hover {
      transform: scale(1.02);
    }
  }
}

.main-font {
  font-family: $fontArabic;
  font-style: normal;
}

.language-option {
  background: transparent;
  border: none;
  color: #717171;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;

  @include respond2(1150px) {
    font-size: 26px;
  }
  @include respond2(900px) {
    font-size: 24px;
  }
  @include respond2(500px) {
    font-size: 20px;
  }
}

.theme-tag {
  transition: all 0.3s ease;
  border-radius: 8px;
  padding: 12px;
  &:hover {
    background: transparent;
  }
  svg {
    @include respond2(900px) {
      height: 32px !important;
      width: 32px !important;
    }
    @include respond2(500px) {
      height: 29px !important;
      width: 29px !important;
    }
  }
  svg path {
    transition: all 0.3s ease;
  }

  &:hover svg path {
    fill: $primary-color;
  }
  @include respond2(1150px) {
    padding: 8px;
  }
  @include respond2(900px) {
    padding: 6px;
  }
  @include respond2(500px) {
    padding: 4px;
  }
}

.theme-tag.svg-stroke {
  &:hover svg path {
    stroke: $primary-color;
    fill: none !important;
  }
}

.theme-tag {
  &:hover .svg-stroke path {
    stroke: $primary-color;
    fill: none !important;
  }
}

.hide {
  display: none;
}
