@import "./bodyFoundation";
@import "./space";
@import "./component";
@import "./menu";

//====================================
//========================HH==EE==ADER
//====================================
.side-footer-grand-wrapper {
  position: relative;
}
.header-section {
  @include respond2(1245px) {
    direction: rtl;
  }
}
section.header-section {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  width: 100%;
}

header {
  background: rgba(35, 35, 35, 0.72);
  backdrop-filter: blur(80px);
  @include respond2(1245px) {
    height: 96px;
  }
}

.small-nav {
  position: relative;
  display: none;
  @include respond2(1245px) {
    display: inline-block;
    height: 100%;
    width: 100%;
    padding-left: 72px;
    padding-right: 72px;
  }
  @include respond2(1000px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @include respond2(692px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .small-nav-logo-abs {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include respond2(692px) {
      height: 50px;
      width: 72px;
    }
  }

  .small-nav-inner {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 50px;
    @include respond2(692px) {
      padding-right: 56px;
    }
  }
}

.side-navbar-wrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 48px;
  height: 100vh;
  width: 100%;

  position: fixed;
  right: 0;
  text-align: right;
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  padding-top: 101px;

  z-index: 102;
  ul {
    padding: 0;
    display: inline-flex;
    flex-direction: column;
    list-style: none;
    gap: 32px;
    padding-right: 25px;
  }

  .nav-links {
    font-size: 22px;
    font-weight: 460;
  }
}

.side-navbar-wrapper.hidde {
  opacity: 0;
  width: 0;
  right: -10px;
}
.side-navbar-wrapper.block {
  opacity: 1;
  width: 200px;
  right: 0;
}

.nav-close-btn {
  width: 36px;
}

.nav-links {
  color: #949493;
  display: inline-block;
  font-weight: 460;
  font-size: 26px;
  line-height: 26px;
  text-decoration: none;
  white-space: nowrap;
}

.small-nav-links {
  text-decoration: none;
  font-size: 22px;
  color: #949493;
  font-weight: 460;
}

nav {
  color: #fff;
  padding-top: 16px;
  padding-bottom: 16px;
  // padding-left: 100px;
  // padding-right: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 100px;
  padding-right: 100px;
  @include respond2(1300px) {
    padding-left: 72px;
    padding-right: 72px;
  }
}

// ===========================================
// =================Appereance    ============
// ===========================================

.language-menu {
  text-align: right;
}

.dropdown-toggle::after {
  color: #949493;
}

.dropdown-menu {
  background: rgb(35, 35, 35);
  -webkit-backdrop-filter: blur(80px);
  backdrop-filter: blur(80px);
  border: none;
  text-align: right;
}

.dropdown-item {
  color: $body-background-light;
}

nav.big {
  @include respond2(1245px) {
    display: none;
  }
}

.nav-links-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  ul {
    flex-direction: row-reverse;
  }
}

ul,
li {
  list-style: none;
}

nav ul {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 40px;
  @include respond2(1350px) {
    gap: 25px;
  }

  a {
    color: #949493;
    font-weight: 460;
    font-size: 26px;
    line-height: 26px;
  }
}

// for smaller

.navbar-burger {
  display: none;
  @include respond2(1100px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 0 5rem;
  }

  .navbar-burger-items {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2.4rem;
  }
}

.offcanvas {
  height: 100vh;
}
.navbar-burger-logo {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}

// ===================================
// ===================section showcase
// ===================================

.showcase-section {
  position: relative;
}

.showcase-bg-atmosphere-logo-1,
.showcase-bg-atmosphere-logo-2 {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  height: 100%;
  width: 100%;
  z-index: -999;
  opacity: 0.2;
  left: -5px;
  top: -10px;
}

.showcase-bg-atmosphere-logo-1.overlay {
  background: -moz-linear-gradient(
    180deg,
    #000000 44.01%,
    rgba(0, 0, 0, 0) 170%
  );
  background: -webkit-linear-gradient(
    180deg,
    #000000 44.01%,
    rgba(0, 0, 0, 0) 170%
  );
  background: -o-linear-gradient(180deg, #000000 44.01%, rgba(0, 0, 0, 0) 170%);
  background: -ms-linear-gradient(
    180deg,
    #000000 44.01%,
    rgba(0, 0, 0, 0) 170%
  );
  background: linear-gradient(180deg, #000000 44.01%, rgba(0, 0, 0, 0) 170%);
}

.showcase-bg-atmosphere-logo-1 {
  background-image: url(../../public/images/HeroShade.png);
  height: 700px;
  width: 102%;
  //   @include respond2(762px) {
  //     background-image: url(../../public/images/HeroShadePhone.png);
  //   }
}

.showcase-bg-atmosphere-logo-2 {
  background-image: url(../../public/images/HeroPattern.png);
  mix-blend-mode: luminosity;
  background-position: -437px 93%;
  height: 107%;
  z-index: -998;
  //   @include respond2(762px) {
  //     background-image: url(../../public/images/HeroPatternPhone.png);
  //     background-position: 191% 0%;
  //   }
}

.showcase {
  padding-top: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .test {
    margin-top: 24px;
  }
}

.showcase-image {
  height: 100%;
  max-width: 100%;

  &-img {
    height: 498px;
    width: 498px;
    background-image: url(../../public/images/heroImage.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include respond2(1300px) {
      height: 403px;
      width: 403px;
    }
  }
}
.showcase-content {
  text-align: right;
  max-width: 604px;
  h1 {
    margin-bottom: 24px;
    @include respond2(1150px) {
      max-width: 540px;
      margin-left: auto;
    }
    @include respond2(800px) {
      font-size: 54px;
    }
    @include respond2(471px) {
      font-size: 48px;
    }
    @include respond2(422px) {
      font-size: 40px;
    }
  }
  p {
    line-height: 180%;
    /* identical to box height, or 36px */
    &:nth-child(3) {
      margin: 4px 0;
    }
  }
}

.showcase-content {
  @include respond2(1150px) {
    max-width: none;
  }
}
.showcase-image {
  @include respond2(1150px) {
    display: none;
  }
}

.hero-image-small {
  display: none;
  @include respond2(1150px) {
    margin: 16px 0;
    display: block;
    height: 320px;
    width: 100%;
    border-radius: 16px;
  }
  @include respond2(471px) {
    height: 250px;
  }

  &-img {
    border-radius: 16px;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../public/images/heroImagePhone.png);
  }
}

// ===================================
// ===================Footer SECTION
// ===================================

.footer-section {
  position: relative;
}
// bg image scss comes here
.footerBG {
  background-image: url(../../public/images/FooterShade.png);
  height: 500px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -10;
  mix-blend-mode: color-dodge;
  @include respond2(1428px) {
    height: 400px;
  }
}

.footerbgoverlay {
  background: -moz-linear-gradient(
    180deg,
    rgba(20, 20, 20, 1) -282%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(20, 20, 20, 1) -282%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -o-linear-gradient(
    180deg,
    rgba(20, 20, 20, 1) -282%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -ms-linear-gradient(
    180deg,
    rgba(20, 20, 20, 1) -282%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(20, 20, 20, 1) -282%,
    rgba(255, 255, 255, 0) 100%
  );

  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -9;
  mix-blend-mode: color-dodge;
  @include respond2(1428px) {
    height: 400px;
  }
}
//=======================
// product page section==
//=======================
.product-page-section {
  position: relative;

  .bg-to-top {
    height: 650px;
  }
}

.product-section.page-divider {
  margin-top: 100px;
}

.product-page-wrapper {
  margin-bottom: 200px;
}
.product {
  position: relative;
  text-align: right;
  p {
    margin: 16px 0;
    &:nth-child(2) {
      font-weight: 460;
    }
  }
}

.product-tags {
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  @include respond2(600px) {
    gap: 16px;
  }
}

.product-tags {
  .molecule-wrapper {
    @include respond2(513px) {
      width: 100%;
      justify-content: center;
    }
  }
}
.product-images {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  gap: 28px;
  @include respond2(513px) {
    height: 280px;
  }
}
.product-left-image {
  flex-grow: 1;
  @include respond2(900px) {
    flex-grow: 2;
  }
  @include respond2(513px) {
    display: none;
  }
}
.product-right-image {
  flex-grow: 2;
  // @include respond2(1150px) {
  //   flex-grow: 1;
  // }
}
.product-left-image,
.product-right-image {
  //   width: 100%;
  height: 100%;
  border-radius: 16px;
}

.product-left-image-img,
.product-right-image-img {
  border-radius: 16px;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.product-left-image-img {
  background-image: url(../../public/images/product5.png);
}
.product-right-image-img {
  background-image: url(../../public/images/strawberry.jpg);
}

// ===================================
// ===================Join US SECTION
// ===================================

.join-us {
  p.join-us-paragraph {
    font-weight: 420;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height */
    margin-top: 24px;
    margin-bottom: 16px;
    @include respond2(600px) {
      @include fontOn600Px;
    }
  }
}

// ===================================
// ===================Contact SECTION
// ===================================
.contact-wrapper {
  text-align: right;
}

.contact {
  border-radius: 16px;
  position: relative;
}
.contact {
  .molecule-wrapper,
  a {
    @include respond2(513px) {
      width: 100%;
      justify-content: center;
    }
  }
}
.contact-background-shade {
  background-image: url(../../public/images/ContactShade.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -9;
  border-radius: 16px;
  opacity: 0.25;
}

.contact-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  @include respond2(900px) {
    flex-direction: column-reverse;
    align-items: initial;
  }
}

// form
.form {
  background-color: $body-background;
  padding: 32px;
  border-radius: 16px;
  text-align: right;

  .primary-btn-fw {
    max-width: 295px;
    @include respond2(600px) {
      max-width: 100% !important;
    }
  }

  @include respond2(513px) {
    padding: 24px;
  }
}

.flex-display-for-form-inner {
  display: flex;
  flex-direction: row-reverse;
  gap: 24px;
  @include respond2(1000px) {
    flex-direction: column;
  }
  & :nth-child(1) {
    flex: 1;
  }
  & :nth-child(2) {
    flex: 1;
  }
}

.form input,
.form textarea {
  font-size: 20px;
  text-align: right;
}

.form textarea {
  padding: 0 16px;
}

.form label {
  font-weight: regular;
  font-size: 26px;
  line-height: 26px;
  /* identical to box height */
  margin-bottom: 24px;
  color: #ffffff;
}

.form input {
  padding: 0 16px;
  margin-bottom: 32px;
}

.form-row input[type="text"]:focus,
.form-row textarea:focus {
  outline: 1.5px solid #ffe8a3;
}

.form button.primary-btn-fw {
  margin-top: 40px;
}

// twitter timeline
.twitter-container {
  max-height: 740px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 16px !important;

  // scrollbar
  // Firefox
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  // Internet Explorer +10
  -ms-overflow-style: none;
  // Chrome, Safari, newer versions of Opera
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }

  @include respond2(900px) {
    max-height: 570px;
  }
  @include respond2(600px) {
    max-height: 450px;
  }
}

// ===================================
// ===================FOOTER SECTION
// ===================================

.footer-section {
  position: relative;
  // .footer-shade {
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   height: 250%;
  //   width: 100%;
  //   background-image: url(../../public/images/FooterShade.png);
  //   background-position: bottom;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   z-index: -5;
  //   mix-blend-mode: soft-light;
  // }
  .footer-shade2 {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 250%;
    width: 100%;
    background-image: url(../../public/images/FooterShade.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -4;
    opacity: 0.2;
  }
}

.footer-top-left,
.footer-top-right {
  text-align: right;
}

.footer-top-left,
.footer-top-right,
.footer-company-dedatils {
  @include respond2(577px) {
    width: 100%;
  }
}
.footer-company-details {
  @include respond2(513px) {
    a,
    .molecules-wrapper {
      width: 100%;
    }
  }
}

.footer {
  .molecule-wrapper,
  a {
    @include respond2(577px) {
      width: 100%;
      justify-content: center;
    }
  }
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include respond2(1020px) {
    flex-direction: column-reverse;
    gap: 32px;
    align-items: flex-end;
  }
}

.footer p.p-title {
  font-weight: 420;
  font-size: 26px;
  line-height: 26px;
  /* identical to box height */

  color: $body-color;
  margin-bottom: 16px;
}

.footer-company-dedatils {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  @include respond2(577px) {
    flex-direction: column;
  }
}

.footer-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 0 60px;
  gap: 16px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height */
    @include respond2(513px) {
      text-align: center;
    }
    @include respond2(350px) {
      font-size: 19px;
    }
  }

  @include respond2(800px) {
    flex-direction: column;
  }

  @include respond2(410px) {
    padding: 60px 0 48px;
  }
}

//  product page
.product-page.container-1440 {
  padding: 0;
}

.title1 {
  font-size: 48px;
  font-weight: 460;
  margin-bottom: 16px;
}

.title2 {
  font-size: 38px;
  font-weight: 460;
  margin-top: 32px;
  margin-bottom: 16px;
}

.title3 {
  font-size: 28px;
  font-weight: 460;
}

.paragraph-about {
  font-size: 20px;
  font-weight: 420;
  line-height: 36px;
  color: #b9b9b9;
  @include respond2(600px) {
    @include fontOn600Px;
  }
}

.p24-32 {
  margin: 24px 0 32px;
}

.p16-32 {
  margin: 16px 0 32px;
}

.right-para-content1 {
  width: 604px;

  @include respond2(1440px) {
    width: 100%;
  }
}

.about-bg-small {
  display: none;

  @include respond2(1162px) {
    display: block;
    width: 100%;
    height: 450px;
    background-image: url(../../public/images/LogoAbout.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  @include respond2(500px) {
    height: 450px;
  }
}

// ========================================
// ================R & D page========================
// ========================================
.research-and-development-wrapper {
  text-align: right;
}

.resources-development-img {
  background-image: url("../../public/images/resourcesAndDevelopment.png");
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  @include respond2(845px) {
    height: 420px;
    background-position: center;
  }
}

/* Components */
.blt-point-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  gap: 16px;
  justify-content: flex-end;
}

// =====================================
// =================About page Styles ==================== think
// =====================================
.about {
  text-align: right;
}

.new-first-content-wrapper {
  display: grid;
  grid-template-columns: 1fr 60%;
  justify-content: center;
  align-items: center;
  @include respond2(1435px) {
    grid-template-columns: 1fr 59%;
  }
  @include respond2(1162px) {
    grid-template-columns: 1fr;
  }
}

.new-left-logo-about {
  background-image: url("../../public/images/LogoAbout.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
  width: 100%;
  @include respond2(1162px) {
    display: none;
  }
}

.img-strawberry {
  background-image: url("../../public/images/strawberry.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0px 53%;
  border-radius: 16px;
  height: 470px;
  width: 100%;
  @include respond2(1130px) {
    height: 400px;
  }
  @include respond2(900px) {
    height: 330px;
  }
  @include respond2(643px) {
    height: 305px;
  }
}

.img-beans {
  background-image: url("../../public/images/CoffeeAbout.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom 34% left 0px;
  border-radius: 16px;
  height: 470px;
  width: 100%;
  @include respond2(1130px) {
    height: 400px;
  }
  @include respond2(900px) {
    height: 330px;
  }
  @include respond2(760px) {
    background-position: bottom 7% left 0px;
  }
  @include respond2(643px) {
    background-size: 120%;
    height: 305px;
    background-position: bottom 32% right 0px;
  }
  @include respond2(500px) {
    background-size: 150%;
    background-position: bottom 32% right 41%;
  }
}

.right-img-strawberry {
  background-image: url("../../public/images/strawberry.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 305px;
  width: 604px;
  border-radius: 16px;
  @include respond2(924px) {
    width: 100%;
    background-position: center;
  }
}

.left-para-content2 {
  width: 604px;
  @include respond2(924px) {
    width: 100%;
  }
}

// ========================
// product page styles //
// ========================

.product-card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(336px, 1fr));
  gap: 32px;
  justify-content: space-between;
  @include respond2(400px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

@media screen and (max-width: 1373px) {
  .product-card-wrapper {
    gap: 24px;
  }
}

// For the time being, disable this until further notice. jahhahaha
// @media screen and (max-width: 1199px) {
//   .product-card-wrapper {
//     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//   }
// }

/* Cards Body Styling */
.card {
  background-color: #191919;
  border: none;
  display: inline-block;
  text-align: right;
  padding: 32px;
  border-radius: 10px;

  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1373px) {
  .card {
    padding: 24px;
  }
}

/* Card category title with logo */
.logo-title-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.card-title {
  font-size: 32px;
  font-weight: 460;
  margin-bottom: 0;
}

@media screen and (max-width: 1373px) {
  .card-title {
    font-size: 26px;
  }
}

.card-title2 {
  color: #d6d6d6;
  font-size: 18px;
  font-weight: 420;
  margin-top: 8px;
  margin-bottom: 6px;
}

/* Card product title */
.card-description-container {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  gap: 8px;
}

@media screen and (max-width: 1373px) {
  .card-description-container {
    gap: 4px;
  }
}

.product-list-line1 {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.product-title {
  font-size: 16px;
  color: #989898;
}

@media screen and (max-width: 1373px) {
  .product-title {
    font-size: 14px;
  }
}

.description-bulletpoint {
  width: 5px;
  height: 5px;
  border-radius: 10px;
  background-color: #9c9b9c;
}

/* Images Styling */
.product-cards {
  margin: 24px 0;
}

.product-items {
  background-size: cover;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
}

@media screen and (max-width: 1373px) {
  .product-items {
    height: 80px;
  }
}

.product-flex-row {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

.flex-row1 {
  margin-bottom: 8px;
}

@media screen and (max-width: 1199px) {
  .product-flex-row {
    gap: 6px;
  }

  .flex-row1 {
    margin-bottom: 6px;
  }
}

.research-img {
  background-image: url(../../public/images/LogoAbout.png);
  height: 208px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 24px 0;
}

@media screen and (max-width: 1373px) {
  .research-img {
    height: 168px;
  }
}

@media screen and (max-width: 1199px) {
  .research-img {
    height: 166px;
  }
}

.consumer-sector .item1 {
  background-image: url(../../public/images/berries.png);
  flex-grow: 1;
}

.consumer-sector .item2 {
  background-image: url(../../public/images/coffee.png);
  flex-grow: 2;
}

.consumer-sector .item3 {
  background-image: url(../../public/images/strawberry.jpg);
  flex-grow: 2;
}

.consumer-sector .item4 {
  background-image: url(../../public/images/vegetable.png);
  flex-grow: 1;
}

.business-sector .item1 {
  background-image: url(../../public/images/meat.png);
  flex-grow: 1;
}

.business-sector .item2 {
  background-image: url(../../public/images/Rice.png);
  flex-grow: 2;
}

.business-sector .item3 {
  background-image: url(../../public/images/DriedStrawberries.png);
  flex-grow: 2;
}

.business-sector .item4 {
  background-image: url(../../public/images/vegetable.png);
  flex-grow: 1;
}
//end product page styles

// =====================================
// ===============contact page
// =====================================
.contact-page-wrapper {
  text-align: right;
}
.contact-page-heading {
  font-weight: 460;
  font-size: 36px;
  line-height: 36px;
}

.contact-page-contact {
  background: -moz-linear-gradient(
    180deg,
    rgba(16, 16, 16, 0.56) 0%,
    rgba(51, 51, 51, 0) 100%
  );
  background: -o-linear-gradient(
    180deg,
    rgba(16, 16, 16, 0.56) 0%,
    rgba(51, 51, 51, 0) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(16, 16, 16, 0.56) 0%,
    rgba(51, 51, 51, 0) 100%
  );
  background: -ms-linear-gradient(
    180deg,
    rgba(16, 16, 16, 0.56) 0%,
    rgba(51, 51, 51, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(16, 16, 16, 0.56) 0%,
    rgba(51, 51, 51, 0) 100%
  );
  border-radius: 16px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 32px;
  @include respond2(700px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  .form {
    background-color: transparent !important;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.map {
  height: 600px;
  background-color: gray;
  border-radius: 16px;
  iframe {
    height: 100% !important;
    width: 100% !important;
    border-radius: 16px;
  }
}

// ===================================
// =================== Light Theme
// ===================================
.twitter-embed {
  overflow-y: hidden;
}
.light {
  background-color: $body-background-light;
  header {
    background: rgba(255, 255, 255, 0.72);
  }
  .navigation__background {
    background: $body-background-light;
  }
  .theme-tag {
    &:hover {
      background: transparent;
    }
    &:hover svg path {
      fill: $primary-color;
    }
  }
  .hover-primary {
    &:hover {
      color: $primary-color;
    }
  }
  .nav-active {
    color: $primary-color !important;
    &::after {
      background-color: $primary-color;
    }
    &:hover {
      color: $primary-color;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #141414;
  }

  p {
    color: #141414;
  }

  .molecule-wrapper {
    background-color: #f9f9f9;
    border: 2px solid #f5f5f5;
  }

  .molecute-point {
    background-color: #717171;
  }

  .molecule-p {
    color: #717171;
  }

  .section-link-atag {
    color: #141414;
    &:hover {
      color: #f1c338;
    }
    svg path {
      transition: all 0.3s ease;
    }
    &:hover svg {
      path {
        stroke: #f1c338;
      }
    }
  }

  .contact-background-shade {
    background-image: url(../../public/images/ContactShadeLight.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -9;
    border-radius: 16px;
    mix-blend-mode: difference;
    filter: hue-rotate(192deg);
    opacity: 100;
  }

  .primary-hover-styles {
    background: #fff;
    border: 2px solid #f5f5f5;
    backdrop-filter: blur(80px);
    path {
      fill: #141414;
    }
    p,
    &.molecule-p {
      color: #141414;
    }

    &-hover {
      background: $white;
      border: 2px solid rgb(172, 171, 171);
      p,
      &.molecule-p {
        color: #717171;
      }
      path {
        fill: #f1c338;
      }
    }

    &-clicked {
      background: #141414;
      p,
      &.molecule-p {
        color: $body-background-light;
      }
      path {
        fill: #f1c338;
      }
    }
  }

  .form {
    background-color: $white;
  }

  .form label {
    color: #141414;
    font-style: normal;
    font-weight: 460;
    font-size: 26px;
    line-height: 26px;
  }

  .form-row input[type="text"],
  textarea {
    color: $body-background;
  }

  input,
  textarea {
    box-sizing: border-box;
    background: #f9f9f9;
  }

  .primary-btn-fw {
    background-color: #ffe8a3;
    color: #141414;
    border: 0;
  }

  .section-background {
    background: $white;
    backdrop-filter: blur(80px);
  }
  .bg-to-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-image: url(../../public/images/heroShadeLight.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 850px;
    z-index: -999;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -99999;
      background: -ms-linear-gradient(
        180deg,
        rgba(255, 77, 20, 0.18) 0%,
        rgba(25, 45, 49, 0.18) 100%
      );
      background: -o-linear-gradient(
        180deg,
        rgba(255, 77, 20, 0.18) 0%,
        rgba(25, 45, 49, 0.18) 100%
      );
      background: -moz-linear-gradient(
        180deg,
        rgba(255, 77, 20, 0.18) 0%,
        rgba(25, 45, 49, 0.18) 100%
      );
      background: -webkit-linear-gradient(
        180deg,
        rgba(255, 77, 20, 0.18) 0%,
        rgba(25, 45, 49, 0.18) 100%
      );
      background: linear-gradient(
        180deg,
        rgb(255, 246, 253) 0%,
        rgb(255, 242, 230) 44%,
        #f7f7f7 100%
      );
      mix-blend-mode: difference;
      opacity: 0.35;
    }
  }

  .bg-to-bottom {
    background-image: url(../../public/images/FooterShadeLight.png);
    height: 810px;

    &::after {
      background: -ms-linear-gradient(
        180deg,
        rgba(255, 77, 20, 0.18) 0%,
        rgba(25, 45, 49, 0.18) 100%
      );
      background: -o-linear-gradient(
        180deg,
        rgba(255, 77, 20, 0.18) 0%,
        rgba(25, 45, 49, 0.18) 100%
      );
      background: -moz-linear-gradient(
        180deg,
        rgba(255, 77, 20, 0.18) 0%,
        rgba(25, 45, 49, 0.18) 100%
      );
      background: -webkit-linear-gradient(
        180deg,
        rgba(255, 77, 20, 0.18) 0%,
        rgba(25, 45, 49, 0.18) 100%
      );
      background: linear-gradient(
        180deg,
        rgb(255, 246, 253) 0%,
        rgb(255, 242, 230) 44%,
        #f7f7f7 100%
      );
      mix-blend-mode: difference;
      opacity: 0.25;
      transform: rotate(-180deg);
    }
  }
  .showcase-bg-atmosphere-logo-1.overlay {
    background: -moz-linear-gradient(
      180deg,
      #ffffff 100.01%,
      rgba(253, 253, 253, 0) 170%
    );
    background: -webkit-linear-gradient(
      180deg,
      #fafafa 44.01%,
      rgba(255, 255, 255, 0) 170%
    );
    background: -o-linear-gradient(
      180deg,
      #fdfdfd 44.01%,
      rgba(0, 0, 0, 0) 170%
    );
    background: -ms-linear-gradient(
      180deg,
      #fdfdfd 44.01%,
      rgba(248, 246, 246, 0) 170%
    );
    background: linear-gradient(180deg, #ffffff 44.01%, rgba(0, 0, 0, 0) 170%);
  }

  .showcase-bg-atmosphere-logo-2 {
    background-image: url(../../public/images/HeroPatternLight.png);
    opacity: 0.4;
  }
  .showcase-bg-atmosphere-logo-1 {
    background-image: url(../../public/images/heroShadeLight.png);
    //   @include respond2(762px) {
    //     background-image: url(../../public/images/HeroShadePhone.png);
    //   }
  }
  .showcase-image {
    &-img {
      background-image: url(../../public/images/heroImageLight.png);
      @include respond2(1300px) {
        height: 403px;
        width: 403px;
      }
    }
  }

  .card {
    background-color: $white;
  }

  .card-title {
    color: #141414;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
  }

  .contact-page-contact {
    background: $white;
  }

  .footer p.p-title {
    font-weight: 420;
    font-size: 26px;
    /* identical to box height */

    color: $body-color-light;
  }
}

// ================================================
// ====================en version==================
// ================================================

.en {
  // header
  .header-section {
    direction: rtl;
  }
  .navigation__background {
    direction: ltr;
  }
  // showcase
  .showcase {
    direction: rtl;
  }
  .showcase-content {
    text-align: left;
    h1 {
      @include respond2(1150px) {
        margin-left: initial;
        margin-right: auto;
      }
    }
  }
  // products
  .product {
    direction: rtl;
    text-align: left;
  }
  // contact
  .contact-wrapper {
    text-align: left;
  }
  // form {
  .form {
    text-align: left;
    input,
    textarea {
      text-align: left;
    }
  }
  .flex-display-for-form-inner {
    flex-direction: row;
    @include respond2(1000px) {
      flex-direction: column;
    }
  }
  // footer
  .footer-top-left,
  .footer-top-right {
    text-align: left;
  }
  .footer-top {
    align-items: flex-start;
  }
  // About Page
  .about {
    direction: rtl;
    text-align: left;
  }
  // contact page
  .contact-page-wrapper {
    text-align: left;
  }
  // product page
  .card {
    direction: rtl;
    text-align: left;
  }
  // R and D Page
  .research-and-development-wrapper {
    direction: rtl;
    text-align: left;
  }

  // molecules
  .section-link {
    left: unset;
    right: 0;
  }
  .section-link-icon {
    margin-right: unset;
    margin-left: 8px;
  }
  .section-link-atag {
    direction: rtl;
  }
}
