/* ======================= */
/* sidemenu background and icon */
/* ======================= */
header {
  position: relative;
}
.navigation {
  position: relative;
  display: none;
  @include respond2(1245px) {
    display: block;
  }

  &__background {
    background: rgba(35, 35, 35, 1);
    backdrop-filter: blur(80px);
    -webkit-backdrop-filter: blur(80px);
    height: 100vh;
    width: 50px;
    position: fixed;
    top: 0;
    right: -100px;
    opacity: 0;
    z-index: 1000;
    transition: all 0.5s;
    direction: ltr;
  }
}
.open.navigation__background {
  opacity: 1;
  width: 265px;
  right: 0;
}

.sidemenu-wrapper {
  position: absolute;
  right: 73px;
  top: 39px;
  height: 20px;
  width: 30px;
  border: none;
  z-index: 2000;
  @include respond2(1000px) {
    right: 50px;
  }
  @include respond2(692px) {
    right: 16px;
  }
}

.hamburger-icon {
  background: transparent;
  border: none;
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  
  &:hover span {
    background: $primary-color;
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #949493;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 8px;
    }
    &:nth-child(3) {
      top: 16px;
    }
  }
}

.hamburger-icon.open {
  span {
    &:nth-child(1) {
      top: 8px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
    }
    &:nth-child(2) {
      opacity: 0;
      left: -60px;
    }
    &:nth-child(3) {
      top: 8px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
  }
}
